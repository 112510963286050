import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Heading,
  Table,
  Box,
  Tbody,
  UnorderedList,
  Th,
  Td,
  Thead,
  Tr,
  TableContainer,
  ListItem,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Image,
  Flex,
  VStack
} from "@chakra-ui/react";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import ReactMarkdown from "react-markdown";
import Link from "src/renderer/link";
import remarkGfm from 'remark-gfm'

const components = (fontSize = 'sm') => ({
  h1(props) {
    return <Heading as="h1" size='xl' {...props} />;
  },
  h2(props) {
    return <Heading as="h2" size='lg' {...props} />;
  },
  h3(props) {
    return <Heading as="h3" size='sm' {...props} />;
  },
  h4(props) {
    return <Heading as="h4" size='sm' {...props} />;
  },
  h5(props) {
    return <Heading as="h5" size='xs' {...props} />;
  },
  ul(props) {
    return <UnorderedList>{props.children}</UnorderedList>;
  },
  li(props) {
    return <ListItem>{props.children}</ListItem>;
  },
  p(props) {
    return <Text fontSize={fontSize}>{props.children}</Text>
  },
  table: ({ node, ...props }) => (
    <table style={{ borderCollapse: "collapse", "width": "100%" }} {...props} />
  ),
  th: ({ node, ...props }) => (
    <th {...props} style={{ border: '1px solid black', padding: '8px' }} />
  ),
  td: ({ node, ...props }) => (
    <td {...props} style={{ border: '1px solid black', padding: '8px' }} />
  ),
  code(props) {
    const { children, className, node, ...rest } = props;
    const match = /language-(\w+)/.exec(className || "");
    if (match && ["info", "warning", "error", "success"].includes(match[1])) {
      return (
        <Alert fontFamily='arial' status={match[1]} variant="left-accent" whiteSpace="initial">
          <AlertIcon />
          <AlertDescription>
            {/* need to nest call otherwise **example** will not render in bold */}
            <Markdown children={children} />
          </AlertDescription>
        </Alert>
      );
    }
    if (match && match[1] === "table") {
      try {
        const tableContent = JSON.parse(children);
        return (
          <Flex>
            <Box boxSize={8} display='block' me='2' >
              <Image src='/logos/gsheets.svg' />
            </Box>
            <TableContainer whiteSpace='normal' flex={1}>
              <Table variant="simple" borderColor='gray.300' borderWidth='1px'>
                <Thead bg='#E6E6E6'>
                  <Tr>
                    {tableContent[0].map((header) => (
                      <Th key={header} p={1} borderColor='gray.300' borderWidth='1px' fontSize="sm" color='black' fontWeight='bold' textTransform='inherit'>
                        {header}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {tableContent.slice(1).map((row, i) => (
                    <Tr key={i} >
                      {row.map((cell, j) => (
                        <Td key={j} fontSize="sm" fontFamily='arial' p={1} borderColor='gray.300' borderWidth='1px'>
                          {cell}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        );
      } catch (e) {
        console.error(e)
        return <code {...rest}>{children}</code>;
      }
    } else if (match && match[1] === "accordion") {
      try {
        const accordionContent = JSON.parse(children);
        return <Accordion mt={2} allowMultiple={false} allowToggle>
          {
            accordionContent.map((item, i) => (
              <AccordionItem key={item.title}>
                <AccordionButton pt="1">
                  <Flex align='center' w="full">
                    <Box boxSize={8} me='2'>
                      <Image src={`/logos/${item.icon}.svg`} />
                    </Box>
                    <Heading
                      flex="1"
                      textAlign="left"
                      size="xs"
                      mb="0"
                      pb="0"
                      as='h3'
                    >
                      {item.title}
                    </Heading>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel py={2}>
                  <Markdown children={item.content} />
                </AccordionPanel>
              </AccordionItem>
            ))}</Accordion>
      } catch (e) {
        console.error(e)
        return <code {...rest}>{children}</code>;
      }
    }
    return <code {...rest}>{children}</code>;
  },
  a(props) {
    return (
      <Button
        as={Link}
        variant="link"
        colorScheme="blue"
        isExternal
        {...props}
      />
    );
  },
  // p(props) {
  //   console.debug("p", props);
  //   return ""
  //   const { children, className, node, ...rest } = props;
  //   const match = /language-(\w+)/.exec(className || "");
  // },
});

export const getComponents = (fontSize?) => ({ ...ChakraUIRenderer(), ...components(fontSize) })

const Markdown = ({ children, fontSize }: { children: string, fontSize?: string }) => {
  return (
    <VStack spacing={4} align='stretch'>
      <ReactMarkdown
        skipHtml
        // rehypePlugins={[rehypeRaw]} this could be dangerous if somehow inserts some bad html
        remarkPlugins={[remarkGfm]}
        components={getComponents(fontSize)}
        children={children}
      />
    </VStack>
  );
};

export default Markdown;
